import { default as energy_45green_45practicesF3eoPxHQi4Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeymsilN1QRJRMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builder7cpW8HsdFwMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexcyeYsimRkRMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45team3hx3gaVSx9Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderMS3Ss2xc2rMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesFtWELjIVsTMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93tfpOg1m2svMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexJDzQuX0QKSMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexhXpIf0HosvMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93w0gz1tmE2pMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexBT2tO8nGF3Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as ask_45a_45question1OhOCCksTAMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesRgzhzwjAp4Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqlB2dqm14jAMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesF6CJa4qxStMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexMPnkzWympcMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locations89BHH8UhbcMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offerslH0rztdGj0Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexosDVFp5wnYMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexJRmiyS0qlDMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexE47fk9TItMMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexOU1qBdte7bMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexSO5Jh3B1J2Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexY21G02KnA7Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexIg9sLK0ideMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45information6lts1ZOD81Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionSkWOeA9S1MMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placennDoZqYap1Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenance3R7wGzSR6DMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_9343RuWKMLMQMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexfV4bjLFscVMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexAcyKbkGReOMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessVrWzLjy6Q8Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93gDP0OLwZpXMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoritesxQxBacIp1xMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchEnriW5dFLLMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhouseTuAzKippoCMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionfbMLkY6dhfMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottage8UfR6cfIZyMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexidckgW3Tf7Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsO2mtCefDmyMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usb2eZz3M0vtMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsmuNZ2FGoQ6Meta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxpPeDtRbRVsMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93lPQWf5gACfMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubVwyDPhaNwFMeta } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubVwyDPhaNwF } from "/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesF3eoPxHQi4Meta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesF3eoPxHQi4Meta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesF3eoPxHQi4Meta || {},
    alias: energy_45green_45practicesF3eoPxHQi4Meta?.alias || [],
    redirect: energy_45green_45practicesF3eoPxHQi4Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeymsilN1QRJRMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeymsilN1QRJRMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeymsilN1QRJRMeta || {},
    alias: fun_45part_45journeymsilN1QRJRMeta?.alias || [],
    redirect: fun_45part_45journeymsilN1QRJRMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builder7cpW8HsdFwMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builder7cpW8HsdFwMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builder7cpW8HsdFwMeta || {},
    alias: homesafe_45colorado_45master_45builder7cpW8HsdFwMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builder7cpW8HsdFwMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexcyeYsimRkRMeta?.name ?? "about-us",
    path: indexcyeYsimRkRMeta?.path ?? "/about-us",
    meta: indexcyeYsimRkRMeta || {},
    alias: indexcyeYsimRkRMeta?.alias || [],
    redirect: indexcyeYsimRkRMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45team3hx3gaVSx9Meta?.name ?? "about-us-our-team",
    path: our_45team3hx3gaVSx9Meta?.path ?? "/about-us/our-team",
    meta: our_45team3hx3gaVSx9Meta || {},
    alias: our_45team3hx3gaVSx9Meta?.alias || [],
    redirect: our_45team3hx3gaVSx9Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderMS3Ss2xc2rMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderMS3Ss2xc2rMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderMS3Ss2xc2rMeta || {},
    alias: preferred_45lenderMS3Ss2xc2rMeta?.alias || [],
    redirect: preferred_45lenderMS3Ss2xc2rMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesFtWELjIVsTMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesFtWELjIVsTMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesFtWELjIVsTMeta || {},
    alias: realtor_45resourcesFtWELjIVsTMeta?.alias || [],
    redirect: realtor_45resourcesFtWELjIVsTMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93tfpOg1m2svMeta?.name ?? "blog-post",
    path: _91post_93tfpOg1m2svMeta?.path ?? "/blog/:post()",
    meta: _91post_93tfpOg1m2svMeta || {},
    alias: _91post_93tfpOg1m2svMeta?.alias || [],
    redirect: _91post_93tfpOg1m2svMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexJDzQuX0QKSMeta?.name ?? "blog-category-category",
    path: indexJDzQuX0QKSMeta?.path ?? "/blog/category/:category()",
    meta: indexJDzQuX0QKSMeta || {},
    alias: indexJDzQuX0QKSMeta?.alias || [],
    redirect: indexJDzQuX0QKSMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhXpIf0HosvMeta?.name ?? "blog",
    path: indexhXpIf0HosvMeta?.path ?? "/blog",
    meta: indexhXpIf0HosvMeta || {},
    alias: indexhXpIf0HosvMeta?.alias || [],
    redirect: indexhXpIf0HosvMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93w0gz1tmE2pMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93w0gz1tmE2pMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93w0gz1tmE2pMeta || {},
    alias: _91_46_46_46slug_93w0gz1tmE2pMeta?.alias || [],
    redirect: _91_46_46_46slug_93w0gz1tmE2pMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexBT2tO8nGF3Meta?.name ?? "campaigns",
    path: indexBT2tO8nGF3Meta?.path ?? "/campaigns",
    meta: indexBT2tO8nGF3Meta || {},
    alias: indexBT2tO8nGF3Meta?.alias || [],
    redirect: indexBT2tO8nGF3Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45question1OhOCCksTAMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45question1OhOCCksTAMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45question1OhOCCksTAMeta || {},
    alias: ask_45a_45question1OhOCCksTAMeta?.alias || [],
    redirect: ask_45a_45question1OhOCCksTAMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesRgzhzwjAp4Meta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesRgzhzwjAp4Meta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesRgzhzwjAp4Meta || {},
    alias: career_45opportunitiesRgzhzwjAp4Meta?.alias || [],
    redirect: career_45opportunitiesRgzhzwjAp4Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqlB2dqm14jAMeta?.name ?? "contact-us-faq",
    path: faqlB2dqm14jAMeta?.path ?? "/contact-us/faq",
    meta: faqlB2dqm14jAMeta || {},
    alias: faqlB2dqm14jAMeta?.alias || [],
    redirect: faqlB2dqm14jAMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesF6CJa4qxStMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesF6CJa4qxStMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesF6CJa4qxStMeta || {},
    alias: homeowner_45resourcesF6CJa4qxStMeta?.alias || [],
    redirect: homeowner_45resourcesF6CJa4qxStMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexMPnkzWympcMeta?.name ?? "contact-us",
    path: indexMPnkzWympcMeta?.path ?? "/contact-us",
    meta: indexMPnkzWympcMeta || {},
    alias: indexMPnkzWympcMeta?.alias || [],
    redirect: indexMPnkzWympcMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locations89BHH8UhbcMeta?.name ?? "contact-us-our-locations",
    path: our_45locations89BHH8UhbcMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locations89BHH8UhbcMeta || {},
    alias: our_45locations89BHH8UhbcMeta?.alias || [],
    redirect: our_45locations89BHH8UhbcMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offerslH0rztdGj0Meta?.name ?? "current-offers",
    path: current_45offerslH0rztdGj0Meta?.path ?? "/current-offers",
    meta: current_45offerslH0rztdGj0Meta || {},
    alias: current_45offerslH0rztdGj0Meta?.alias || [],
    redirect: current_45offerslH0rztdGj0Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexosDVFp5wnYMeta?.name ?? "events-event",
    path: indexosDVFp5wnYMeta?.path ?? "/events/:event()",
    meta: indexosDVFp5wnYMeta || {},
    alias: indexosDVFp5wnYMeta?.alias || [],
    redirect: indexosDVFp5wnYMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJRmiyS0qlDMeta?.name ?? "events",
    path: indexJRmiyS0qlDMeta?.path ?? "/events",
    meta: indexJRmiyS0qlDMeta || {},
    alias: indexJRmiyS0qlDMeta?.alias || [],
    redirect: indexJRmiyS0qlDMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexE47fk9TItMMeta?.name ?? "homes-location-community",
    path: indexE47fk9TItMMeta?.path ?? "/homes/:location()/:community()",
    meta: indexE47fk9TItMMeta || {},
    alias: indexE47fk9TItMMeta?.alias || [],
    redirect: indexE47fk9TItMMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOU1qBdte7bMeta?.name ?? "homes-location-community-models-model",
    path: indexOU1qBdte7bMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: indexOU1qBdte7bMeta || {},
    alias: indexOU1qBdte7bMeta?.alias || [],
    redirect: indexOU1qBdte7bMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSO5Jh3B1J2Meta?.name ?? "homes-location-community-plans-plan",
    path: indexSO5Jh3B1J2Meta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexSO5Jh3B1J2Meta || {},
    alias: indexSO5Jh3B1J2Meta?.alias || [],
    redirect: indexSO5Jh3B1J2Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexY21G02KnA7Meta?.name ?? "homes-location-community-qmi-home",
    path: indexY21G02KnA7Meta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexY21G02KnA7Meta || {},
    alias: indexY21G02KnA7Meta?.alias || [],
    redirect: indexY21G02KnA7Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexIg9sLK0ideMeta?.name ?? "index",
    path: indexIg9sLK0ideMeta?.path ?? "/",
    meta: indexIg9sLK0ideMeta || {},
    alias: indexIg9sLK0ideMeta?.alias || [],
    redirect: indexIg9sLK0ideMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45information6lts1ZOD81Meta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45information6lts1ZOD81Meta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45information6lts1ZOD81Meta || {},
    alias: knolls_45hoa_45information6lts1ZOD81Meta?.alias || [],
    redirect: knolls_45hoa_45information6lts1ZOD81Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionSkWOeA9S1MMeta?.name ?? "land-acquisition",
    path: land_45acquisitionSkWOeA9S1MMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionSkWOeA9S1MMeta || {},
    alias: land_45acquisitionSkWOeA9S1MMeta?.alias || [],
    redirect: land_45acquisitionSkWOeA9S1MMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placennDoZqYap1Meta?.name ?? "lifestyle-brents-place",
    path: brents_45placennDoZqYap1Meta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placennDoZqYap1Meta || {},
    alias: brents_45placennDoZqYap1Meta?.alias || [],
    redirect: brents_45placennDoZqYap1Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenance3R7wGzSR6DMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenance3R7wGzSR6DMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenance3R7wGzSR6DMeta || {},
    alias: experience_45lower_45maintenance3R7wGzSR6DMeta?.alias || [],
    redirect: experience_45lower_45maintenance3R7wGzSR6DMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_9343RuWKMLMQMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_9343RuWKMLMQMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_9343RuWKMLMQMeta || {},
    alias: _91testimonial_9343RuWKMLMQMeta?.alias || [],
    redirect: _91testimonial_9343RuWKMLMQMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexfV4bjLFscVMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexfV4bjLFscVMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexfV4bjLFscVMeta || {},
    alias: indexfV4bjLFscVMeta?.alias || [],
    redirect: indexfV4bjLFscVMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAcyKbkGReOMeta?.name ?? "lifestyle-reviews",
    path: indexAcyKbkGReOMeta?.path ?? "/lifestyle/reviews",
    meta: indexAcyKbkGReOMeta || {},
    alias: indexAcyKbkGReOMeta?.alias || [],
    redirect: indexAcyKbkGReOMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessVrWzLjy6Q8Meta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessVrWzLjy6Q8Meta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessVrWzLjy6Q8Meta || {},
    alias: whats_45lifefullnessVrWzLjy6Q8Meta?.alias || [],
    redirect: whats_45lifefullnessVrWzLjy6Q8Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93gDP0OLwZpXMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93gDP0OLwZpXMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93gDP0OLwZpXMeta || {},
    alias: _91_46_46_46slug_93gDP0OLwZpXMeta?.alias || [],
    redirect: _91_46_46_46slug_93gDP0OLwZpXMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesxQxBacIp1xMeta?.name ?? "my-favorites",
    path: my_45favoritesxQxBacIp1xMeta?.path ?? "/my-favorites",
    meta: my_45favoritesxQxBacIp1xMeta || {},
    alias: my_45favoritesxQxBacIp1xMeta?.alias || [],
    redirect: my_45favoritesxQxBacIp1xMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchEnriW5dFLLMeta?.name ?? "new-home-search",
    path: new_45home_45searchEnriW5dFLLMeta?.path ?? "/new-home-search",
    meta: new_45home_45searchEnriW5dFLLMeta || {},
    alias: new_45home_45searchEnriW5dFLLMeta?.alias || [],
    redirect: new_45home_45searchEnriW5dFLLMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhouseTuAzKippoCMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhouseTuAzKippoCMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhouseTuAzKippoCMeta || {},
    alias: easyhouseTuAzKippoCMeta?.alias || [],
    redirect: easyhouseTuAzKippoCMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededitionfbMLkY6dhfMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededitionfbMLkY6dhfMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededitionfbMLkY6dhfMeta || {},
    alias: limitededitionfbMLkY6dhfMeta?.alias || [],
    redirect: limitededitionfbMLkY6dhfMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottage8UfR6cfIZyMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottage8UfR6cfIZyMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottage8UfR6cfIZyMeta || {},
    alias: wee_45cottage8UfR6cfIZyMeta?.alias || [],
    redirect: wee_45cottage8UfR6cfIZyMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexidckgW3Tf7Meta?.name ?? "our-homes",
    path: indexidckgW3Tf7Meta?.path ?? "/our-homes",
    meta: indexidckgW3Tf7Meta || {},
    alias: indexidckgW3Tf7Meta?.alias || [],
    redirect: indexidckgW3Tf7Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsO2mtCefDmyMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsO2mtCefDmyMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsO2mtCefDmyMeta || {},
    alias: our_45neighborhoodsO2mtCefDmyMeta?.alias || [],
    redirect: our_45neighborhoodsO2mtCefDmyMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usb2eZz3M0vtMeta?.name ?? "partner-with-us",
    path: partner_45with_45usb2eZz3M0vtMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45usb2eZz3M0vtMeta || {},
    alias: partner_45with_45usb2eZz3M0vtMeta?.alias || [],
    redirect: partner_45with_45usb2eZz3M0vtMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsmuNZ2FGoQ6Meta?.name ?? "privacyterms",
    path: privacytermsmuNZ2FGoQ6Meta?.path ?? "/privacyterms",
    meta: privacytermsmuNZ2FGoQ6Meta || {},
    alias: privacytermsmuNZ2FGoQ6Meta?.alias || [],
    redirect: privacytermsmuNZ2FGoQ6Meta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxpPeDtRbRVsMeta?.name ?? "sandbox",
    path: sandboxpPeDtRbRVsMeta?.path ?? "/sandbox",
    meta: sandboxpPeDtRbRVsMeta || {},
    alias: sandboxpPeDtRbRVsMeta?.alias || [],
    redirect: sandboxpPeDtRbRVsMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93lPQWf5gACfMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93lPQWf5gACfMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93lPQWf5gACfMeta || {},
    alias: _91_46_46_46slug_93lPQWf5gACfMeta?.alias || [],
    redirect: _91_46_46_46slug_93lPQWf5gACfMeta?.redirect,
    component: () => import("/codebuild/output/src3515380310/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/build-with-us/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/career-opportunities/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/email-signup/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/get-updates/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/how-to-video-library/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/lifestyle/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/login/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/lost-password/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/maintenance-items/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/organism-page/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/register/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/reset-password/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/style-guide/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  },
  {
    name: component_45stubVwyDPhaNwFMeta?.name ?? undefined,
    path: component_45stubVwyDPhaNwFMeta?.path ?? "/warranty-service-request/",
    meta: component_45stubVwyDPhaNwFMeta || {},
    alias: component_45stubVwyDPhaNwFMeta?.alias || [],
    redirect: component_45stubVwyDPhaNwFMeta?.redirect,
    component: component_45stubVwyDPhaNwF
  }
]